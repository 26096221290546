import Hero from "../components/Hero/Hero";
import OnlineCasinosInfo from "../components/OnlineCasinoInfo/OnlineCasinoInfo";
import CookieConsent from "../components/Cookie/Cookie";
import OnlineCasino from "../components/OnlineCasino/OnlineCasino";

const Onlinecasino = () => {
  return (
    <div>
      <Hero title={"Os 10 principais cassinos online em Portugal"} />
      <OnlineCasino />
      <OnlineCasinosInfo />

      <CookieConsent />
    </div>
  );
};

export default Onlinecasino;
