import React, { useEffect, useState } from "react";
import CasinoCard from "../CasinoCard/CasinoCard";

const Casinos = () => {
  const mockup = [
    {
      logo: "/images/nossaaposta.svg",
      name: "Nossaaposta",
      rating: 9.9,
      votes: 1500,
      bonus: "750€ EM BONUS",
      deposit: "10€",
      link: "https://www.nossaaposta.pt/?affiliate_id=9843&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/bwin.svg",
      name: "BWIN",
      rating: 9.8,
      votes: 1400,
      bonus: "BONUS DE SLOTS DE 50% ATE 50 EUROS",
      deposit: "10€",
      link: "https://sports.bwin.pt/pt/sports?partner=3382&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/portugal.svg",
      name: "Casino Portugal",
      rating: 9.7,
      votes: 1209,
      bonus: "100% Bonus up to $1000 + $25 Token",
      deposit: "10€",
      link: "https://www.casinoportugal.pt/casino/?ref=2312&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/888casino.svg",
      name: "888 Casino",
      rating: 9.6,
      votes: 1021,
      bonus: "Bônus de 100% até 1.000€ no seu primeiro depósito",
      deposit: "10€",
      link: "https://casino.888.pt/?utm_campaign=topcasinos-2023&utm_medium=referral&utm_source=affiliate",
    },
    {
      logo: "/images/bacanplay.svg",
      name: "BacanaPlay",
      rating: 9.5,
      votes: 1250,
      bonus:
        "Bônus de Boas-Vindas Deposita já e recebe 100% até €250 Código de Bônus: BACANAPT",
      deposit: "10€",
      link: "https://www.bacanaplay.pt/?track=2241&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },

    {
      logo: "/images/luckia.svg",
      name: "Luckia",
      rating: 9.5,
      votes: 1020,
      bonus: "Novos Clientes 500€ Bônus",
      deposit: "10€",
      link: "https://www.luckia.pt/?affcode=1244&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/casino.svg",
      name: "Pt Casino",
      rating: 9.5,
      votes: 1010,
      bonus: "SE QUERES DIVERSÃO VIESTE BATER À PORTA CERTA EXPERIMENTA JÁ!",
      deposit: "10€",
      link: "https://www.placard.pt/apostas?utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023&affiliate=3311",
    },
    {
      logo: "/images/pokerstar.svg",
      name: "PokerStars",
      rating: 9.5,
      votes: 1000,
      bonus: "BONUS DE 100% ATE €1.000",
      deposit: "10€",
      link: "https://www.pokerstars.pt/?&utm_campaign=topcasinos-2023&utm_medium=referral&utm_source=affiliate",
    },
    {
      logo: "/images/esc.svg",
      name: "ESC Online",
      rating: 9.5,
      votes: 1000,
      bonus:
        "Jogue nas slots selecionadas da Pragmatic e ganhe uma parte do Jackpot de 3.000€!",
      deposit: "10€",
      link: "https://www.estorilsolcasinos.pt/?affiliate=4553&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/Solverde.svg",
      name: "Solverde",
      rating: 9.5,
      votes: 1000,
      bonus:
        "Aproveita um bónus de depósito em casino de 100% até 100€ e ganha 30€ em Freebets",
      deposit: "10€",
      link: "https://www.solverde.pt/?pid=4421&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
  ];

  return (
    <section className="bg-gray-900 py-10">
      <h1 className="text-3xl sm:text-4xl font-bold text-white text-center mb-8 sm:mb-10">
        TOP RATED CASINOS
      </h1>
      <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {mockup.map((casino: any, index) => {
          // Check if casino.three is not true
          if (casino.three !== true) {
            return (
              <CasinoCard
                key={index}
                {...casino}
                index={index}
                totalItems={11}
                exclusiveText={casino.exclusiveText} // Example condition for exclusive
              />
            );
          }
          // If the condition is not met, return null to avoid rendering anything
          return null;
        })}
      </div>
    </section>
  );
};

export default Casinos;
