import React, { useEffect, useState } from "react";
import CasinoCard from "../CasinoCard/CasinoCard";

const Plinko = () => {
  const mockup = [
    {
      logo: "/images/nossaaposta.svg",
      name: "Nossaaposta",
      rating: 9.9,
      votes: 1500,
      bonus: "750€ EM BONUS",
      deposit: "10€",
      link: "https://www.nossaaposta.pt/?affiliate_id=9843&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/bwin.svg",
      name: "BWIN",
      rating: 9.8,
      votes: 1400,
      bonus: "BONUS DE SLOTS DE 50% ATE 50 EUROS",
      deposit: "10€",
      link: "https://sports.bwin.pt/pt/sports?partner=3382&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/portugal.svg",
      name: "Casino Portugal",
      rating: 9.7,
      votes: 1209,
      bonus: "100% Bonus up to $1000 + $25 Token",
      deposit: "10€",
      link: "https://www.casinoportugal.pt/casino/?ref=2312&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/888casino.svg",
      name: "888 Casino",
      rating: 9.6,
      votes: 1021,
      bonus: "Bônus de 100% até 1.000€ no seu primeiro depósito",
      deposit: "10€",
      link: "https://casino.888.pt/?utm_campaign=topcasinos-2023&utm_medium=referral&utm_source=affiliate",
    },
    {
      logo: "/images/bacanplay.svg",
      name: "BacanaPlay",
      rating: 9.5,
      votes: 1250,
      bonus:
        "Bônus de Boas-Vindas Deposita já e recebe 100% até €250 Código de Bônus: BACANAPT",
      deposit: "10€",
      link: "https://www.bacanaplay.pt/?track=2241&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },

    {
      logo: "/images/luckia.svg",
      name: "Luckia",
      rating: 9.5,
      votes: 1020,
      bonus: "Novos Clientes 500€ Bônus",
      deposit: "10€",
      link: "https://www.luckia.pt/?affcode=1244&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/casino.svg",
      name: "Pt Casino",
      rating: 9.5,
      votes: 1010,
      bonus: "SE QUERES DIVERSÃO VIESTE BATER À PORTA CERTA EXPERIMENTA JÁ!",
      deposit: "10€",
      link: "https://www.placard.pt/apostas?utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023&affiliate=3311",
    },
    {
      logo: "/images/pokerstar.svg",
      name: "PokerStars",
      rating: 9.5,
      votes: 1000,
      bonus: "BONUS DE 100% ATE €1.000",
      deposit: "10€",
      link: "https://www.pokerstars.pt/?&utm_campaign=topcasinos-2023&utm_medium=referral&utm_source=affiliate",
    },
    {
      logo: "/images/esc.svg",
      name: "ESC Online",
      rating: 9.5,
      votes: 1000,
      bonus:
        "Jogue nas slots selecionadas da Pragmatic e ganhe uma parte do Jackpot de 3.000€!",
      deposit: "10€",
      link: "https://www.estorilsolcasinos.pt/?affiliate=4553&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
    {
      logo: "/images/Solverde.svg",
      name: "Solverde",
      rating: 9.5,
      votes: 1000,
      bonus:
        "Aproveita um bónus de depósito em casino de 100% até 100€ e ganha 30€ em Freebets",
      deposit: "10€",
      link: "https://www.solverde.pt/?pid=4421&utm_source=affiliate&utm_medium=referral&utm_campaign=topcasinos-2023",
    },
  ];

  const newMockData = [
    {
      logo: "/images/mrpunter.svg",
      name: "MrPunter",
      rating: 9.9,
      votes: 1500,
      bonus: "Bónus de Boas-vindas 100% até €500 + 200 FS",
      deposit: "10€",
      link: "https://mrp.fynkelto.com/?mid=240821_1479504&clickid=",
      plinker: true,
      bestSlot: true,
      exclusiveText: "Exclusive Bonus for Plinko",
    },
    {
      logo: "/images/1red.svg",
      name: "1Red",
      rating: 9.8,
      votes: 1500,
      bonus: "Bónus de Boas-vindas 200% até €9300 + 100 GG",
      deposit: "10€",
      link: "https://1redlink.com/h57c0e154?clickid=",
      exclusiveText: "Exclusive Bonus for Plinko",
    },
    // {
    //   logo: "/images/betlabell.svg",
    //   name: "BetLabel",
    //   rating: 9.9,
    //   votes: 1400,
    //   bonus: "Pacote de boas-vindas até €1500 + 150 rodadas grátis",
    //   deposit: "10€",
    //   link: "https://moy.auraodin.com/redirect.aspx?pid=120057&lpid=1119&bid=1650&dynamic=",
    //   plinker: true,
    //   exclusiveText: "Exclusive Bonus for Plinko",
    // },
    // {
    //   logo: "/images/dragonslots.svg",
    //   name: "DragonSlots",
    //   rating: 9.9,
    //   votes: 1400,
    //   bonus: "Bónus de Boas-vindas 225% até €2250 + 200 Rodadas Gratis",
    //   deposit: "10 €",
    //   link: "https://mu.fastmui.com/redirect.aspx?pid=359375&lpid=2483&bid=2159&dynamic=",
    // },
    {
      logo: "/images/zet.svg",
      name: "Zetcasino",
      rating: 9.8,
      votes: 1450,
      bonus: "Bónus de Boas-vindas 100% até €500 + 200 GG",
      deposit: "10€",
      link: "https://ztc.servclick1move.com/?mid=168549_1453015&clickid=",
      exclusiveText: "Levantamentos Imediatos",
    },
    {
      logo: "/images/cashed.svg",
      name: "Cashed",
      rating: 9.8,
      votes: 1500,
      bonus: "100% até €500 + 200 GG",
      deposit: "10€",
      link: "https://cshd.servclick1move.com/?mid=141513_1453029&clickid=",
    },
    {
      logo: "/images/spinight.svg",
      name: "Spinight",
      rating: 9.3,
      votes: 850,
      bonus: "Bónus de Boas-vindas 100% até €500 + 200 GG",
      deposit: "10€",
      link: "https://hub.buzzaffiliates.com/visit/?bta=35281&nci=5388&utm_campaign=INHPT02SPT350&afp=",
    },
    {
      logo: "/images/22bet.svg",
      name: "22Bet",
      rating: 9.7,
      votes: 1130,
      bonus: "Bónus de Primero Depósito até €300",
      deposit: "10€",
      link: "https://moy.auraodin.com/redirect.aspx?pid=120702&lpid=17&bid=1484&dynamic=",
    },

    {
      logo: "/images/directionbet.svg",
      name: "DirectionBet",
      rating: 9.6,
      votes: 1200,
      bonus: "Bónus de Boas-vindas até €1000",
      deposit: "10€",
      link: "https://m.partnersdirectionbet.com/Redirect.aspx?mid=1&sid=241&pid=INHPT02DIR350&affid=68&cid=",
    },
    {
      logo: "/images/casinoly.svg",
      name: "Casinoly",
      rating: 9.8,
      votes: 1500,
      bonus: "Bónus de Boas-vindas 100% até €500 + 200 GG",
      deposit: "10€",
      link: "https://csnl.servclick1move.com/?mid=141513_1453013&clickid=",
    },

    // {
    //   logo: "/images/bizzo.svg",
    //   name: "Bizzo",
    //   rating: 9.5,
    //   votes: 1000,
    //   bonus: "Pacote de boas-vindas até €400 + 150 rodadas grátis",
    //   deposit: "10€",
    //   link: "https://mu.fastmui.com/redirect.aspx?pid=355266&lpid=917&bid=2058&dynamic=",
    // },
    {
      logo: "/images/rtbet.svg",
      name: "RTBet",
      rating: 9.4,
      votes: 900,
      bonus: "Bónus de Casino 100% até €500 + 200 GG + 1 Bonus Crab",
      deposit: "10€",
      link: "https://rtb.xarvilo.com/?mid=2017_1452558&clickid=",
    },

    {
      logo: "/images/greatspin.svg",
      name: "GreatSpin",
      rating: 9.7,
      votes: 1200,
      bonus: "Depósito €50. Jogue com €100",
      deposit: "10€",
      link: "https://media.highaffiliates.com/redirect.aspx?pid=15253&lpid=246&bid=1826&subid=",
    },
    {
      logo: "/images/dazardbet.svg",
      name: "DazardBet",
      rating: 9.5,
      votes: 1150,
      bonus: "100% UP TO €500 + 200FS + 1 BONUS CRAB",
      deposit: "10€",
      link: "https://dzrdbt.servclick1move.com/?mid=245205_1452556&clickid=",
    },
    {
      logo: "/images/slotuna.svg",
      name: "Slotuna",
      rating: 9.4,
      votes: 950,
      bonus: "Bónus de Boas-vindas 100% até €500 + 200 Rodadas Gratis",
      deposit: "10€",
      link: "https://sltna.xarvilo.com/?mid=244057_1452548&clickid=",
    },
    {
      logo: "/images/sportuna.svg",
      name: "Sportuna",
      rating: 9.4,
      votes: 950,
      bonus:
        "Bónus de Boas-vindas 100% até €500 + 200 Rodadas Gratis + 1 Bonus Crab",
      deposit: "10€",
      link: "https://sprt.xarvilo.com/?mid=244057_1452547&clickid=",
    },
    {
      logo: "/images/kinbet.svg",
      name: "Kinbet",
      rating: 9.6,
      votes: 1350,
      bonus: "PACOTE DE BOAS-VINDAS 250% ATÉ €3,000 + 350 GG + 1 BONUS CRAB",
      deposit: "10€",
      link: "https://kinbetlink.com/ofc126e05?visit_id=",
    },
    {
      logo: "/images/betovo.svg",
      name: "Betovo",
      rating: 9.6,
      votes: 1300,
      bonus:
        "Bónus de Boas-vindas do Casino 100% até €2024 + 100 Rodadas Grátis",
      deposit: "10€",
      link: "https://btvo.fynkelto.com/?mid=242104_1452544&clickid=",
    },
    {
      logo: "/images/cazeus.svg",
      name: "CaZeus",
      rating: 9.4,
      votes: 940,
      bonus: "Bónus de Boas-vindas 100% até €500 + 200 Rodadas Gratis",
      deposit: "10€",
      link: "https://czs.servclick1move.com/?mid=236811_1452534&clickid=",
    },
    {
      logo: "/images/tikitaka.svg",
      name: "TikiTaka",
      rating: 9.3,
      votes: 850,
      bonus: "Welcome bonus 100% up to 500€ + 200 FS + 1 Bonus Crab",
      deposit: "10€",
      link: "https://tkt.servclick1move.com/?mid=236811_1452533&clickid=",
    },
    {
      logo: "/images/ivibet.svg",
      name: "Ivibet",
      rating: 9.3,
      votes: 850,
      bonus: "Bónus de Boas-vindas 100% até €300 + 170 Rodadas Gratis",
      deposit: "10€",
      link: "https://playamo.partners/yCMH7Vh9?pid=&clickid=",
    },
    {
      logo: "/images/hellspin.svg",
      name: "HellSpin",
      rating: 9.3,
      votes: 850,
      bonus: "Bónus de Boas-vindas 100% até €300 + 170 Rodadas Gratis",
      deposit: "10€",
      link: "https://playamo.partners/pt7mkKZm?pid=&clickid=",
    },
    {
      logo: "/images/joker8.svg",
      name: "Joker8",
      rating: 9.4,
      votes: 940,
      bonus: "Bónus de Boas-vindas 100% até €500 + 200 GG",
      deposit: "10€",
      link: "https://jkr.servclick1move.com/?mid=171437_1453004&clickid=",
    },
    {
      logo: "/images/quickwin.svg",
      name: "Quickwin",
      rating: 9.4,
      votes: 940,
      bonus: "Bónus de Boas-vindas 100% até €500 + 200 GG",
      deposit: "10€",
      link: "https://qwin.servclick1move.com/?mid=175637_1453005&clickid=",
    },
    // {
    //   logo: "/images/monix.svg",
    //   name: "Monix",
    //   rating: 9.8,
    //   votes: 1500,
    //   bonus: "Pacote de boas-vindas até €6,000 + 600 giros grátis",
    //   deposit: "10€",
    //   link: "https://monixplay.com/h64ebde73?clickid=",
    // },
    {
      logo: "/images/spinoloco.svg",
      name: "Spinoloco",
      rating: 9.2,
      votes: 870,
      bonus: "Bónus de Boas-vindas 100% até €500 + 50 FS",
      deposit: "10€",
      link: "https://record.topsaffiliates.com/_gaKwPcE3gTsDVkvPM80nyWNd7ZgqdRLk/2/?s2s.req_id=&pg=1",
    },
    {
      logo: "/images/goldspin.svg",
      name: "GoldSpin",
      rating: 9.6,
      votes: 1350,
      bonus: "Bónus de Boas-vindas até €1000 + 100 GG",
      deposit: "10€",
      link: "https://gdspn.fynkelto.com/?mid=242104_1469946&clickid=",
    },
  ];

  const [casinosData, setCasinosData] = useState(mockup);
  const [bestPlinkoSites, setBestPlinkoSites] = useState<typeof newMockData>(
    []
  );
  const [bestSlot, setBestSlot] = useState<typeof newMockData>([]);
  const [isMobile, setIsMobile] = useState(false);

  //   useEffect(() => {
  //     const checkIsMobile = () => {
  //       setIsMobile(window.innerWidth < 600);
  //     };

  //     checkIsMobile();
  //     window.addEventListener("resize", checkIsMobile);

  //     return () => window.removeEventListener("resize", checkIsMobile);
  //   }, []);

  //   useEffect(() => {
  //     const now = new Date();
  //     const portugalTime = new Intl.DateTimeFormat("en-US", {
  //       timeZone: "Europe/Lisbon",
  //       hour: "numeric",
  //       hour12: false,
  //     }).format(now);

  //     const currentHour = parseInt(portugalTime, 10);

  //     if (currentHour >= 17 || currentHour < 2) {
  //       setCasinosData(newMockData);
  //       setBestPlinkoSites(newMockData.filter((item) => item.plinker));
  //       setBestSlot(newMockData.filter((item) => item.bestSlot));
  //     } else {
  //       setCasinosData(mockup);
  //       setBestPlinkoSites([]); // Boş dizi olarak ayarlıyoruz
  //       setBestSlot([]); // Boş dizi olarak ayarlıyoruz
  //     }
  //   }, []);

  const totalItems = newMockData.length;

  return (
    <section className="bg-gray-900 py-10">
      <h1 className="text-3xl sm:text-4xl font-bold text-white text-center mb-8 sm:mb-10">
        TOP RATED CASINOS
      </h1>
      <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {mockup.map((casino: any, index) => {
          // Check if casino.three is not true
          if (casino.three !== true) {
            return (
              <CasinoCard
                key={index}
                {...casino}
                index={index}
                totalItems={totalItems}
                exclusiveText={casino.exclusiveText} // Example condition for exclusive
              />
            );
          }
          // If the condition is not met, return null to avoid rendering anything
          return null;
        })}
      </div>
    </section>
  );
};

export default Plinko;
